import './ProjectBlock.scss';
import ProjectItem from './ProjectItem/ProjectItem';
import projectData from './project-data';

function ProjectBlock () {
  return (
    <section className='project-block'>
        <div className='project-block-container'>
            <div className='project-block-title'>
                <h3>Проєкти MOPIS</h3>
                <span>Надихніться атмосферою проєктів команди Mopis зазирнувши як все виглядає з середини. Це те що вже скоро стане ще більш досяжним для кожного</span>
            </div>

            <div className='project-block-info-box'>
                {
                    projectData.map((project, index) => (
                        <ProjectItem
                            key={index}
                            title={project.title}
                            images={project.images}
                            url={project.video.url}
                            preloader={project.video.preloader}
                            image1={project.image1}
                            image2={project.image2}
                            image3={project.image3}
                        />
                    ))
                }
            </div>
        </div>
    </section>
  )
}

export default ProjectBlock;
