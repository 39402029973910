import EDEM1 from '../../images/edem-1.jpeg';
import EDEM2 from '../../images/edem-2.jpeg';
import EDEM3 from '../../images/edem-3.jpeg';
import EDEM4 from '../../images/edem-4.jpeg';

import VALTZ1 from '../../images/valtz-1.jpeg';
import VALTZ2 from '../../images/valtz-2.jpeg';
import VALTZ3 from '../../images/valtz-3.jpg';
import VALTZ4 from '../../images/valtz-4.jpeg';

import VENICE1 from '../../images/venice-1.jpeg';
import VENICE2 from '../../images/venice-2.jpeg';
import VENICE3 from '../../images/venice-3.jpeg';
import VENICE4 from '../../images/venice-4.jpeg';

import MILLANOVA1 from '../../images/millanova-1.jpeg';
import MILLANOVA2 from '../../images/millanova-2.jpeg';
import MILLANOVA3 from '../../images/millanova-3.jpeg';
import MILLANOVA4 from '../../images/millanova-4.jpeg';

import MAGIC1 from '../../images/magic-1.jpeg';
import MAGIC2 from '../../images/magic-2.jpeg';
import MAGIC3 from '../../images/magic-3.jpeg';
import MAGIC4 from '../../images/magic-4.jpeg';

const projectData = [
    {
        title: 'EDEM OF OUR LOVE',
        image1: EDEM1,
        image2: EDEM2,
        image3: EDEM3,
        video: {
            preloader: EDEM4,
            url: 'https://vimeo.com/927898776?share=copy',
        }
    },

    {
        title: 'вальс почуттів',
        image1: VALTZ1,
        image2: VALTZ2,
        image3: VALTZ3,
        video: {
            preloader: VALTZ4,
            url: 'https://vimeo.com/927898213?share=copy',
        }
    },

    {
        title: 'ДЕКОР У ВЕНЕЦІЇ',
        image1: VENICE1,
        image2: VENICE2,
        image3: VENICE3,
        video: {
            preloader: VENICE4,
            url: 'https://vimeo.com/927900253?share=copy',
        }
    },

    {
        title: 'MillaNova',
        image1: MILLANOVA1,
        image2: MILLANOVA2,
        image3: MILLANOVA3,
        video: {
            preloader: MILLANOVA4,
            url: 'https://vimeo.com/927897563?share=copy',
        }
    },

    {
        title: 'Magic all around',
        image1: MAGIC1,
        image2: MAGIC2,
        image3: MAGIC3,
        video: {
            preloader: MAGIC4,
            url: 'https://vimeo.com/927899697?share=copy',
        }
    }
];

export default projectData;