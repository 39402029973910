const resultData = [
    {
        shortText: "Поглянеш",
        longText: "на професію декоратора по-новому"
    },

    {
        shortText: "Освоїш",
        longText: "повну робочу документацію декоратора"
    },

    {
        shortText: "Збудуєш",
        longText: "логічну та ефективну структуру роботи"
    },

    {
        shortText: "Зрозумієш",
        longText: "як виглядає робота декоратора на кожному етапі"
    },

    {
        shortText: "Ознайомишся",
        longText: "з реальними кейсами та практичним досвідом Mopis"
    },

    {
        shortText: "Створиш",
        longText: "маркетингову стратегію та потік клієнтів"
    },

    {
        shortText: "Навчишся",
        longText: "продавати свої послуги дорого"
    },
    {
        shortText: "Досягнеш",
        longText: "нового рівня проєктів та масштабу"
    },
];

export default resultData;