const euroSpeakerData = [
    {
        nickName: "",
        name: "Аліса Василинка",
        text: "Засновниця та SEO весільної агенції Mopis. Поясню просто про цільову та позиціонування. Закохаю в маркетинг та навчу продавати свої послуги ",
        theme: ""
    },

    {
        nickName: "",
        name: "Марта Кузь",
        text: "Керуючий декоратор Mopis Розкажу правдивий шлях декоратора на прикладах. Покажу як працювати з масштабними проєктами та відповім на усі питання.",
        theme: ""
    },

    {
        nickName: "",
        name: "Христина Фо",
        text: "Керуючий флорист Mopis. Навчу, що декоратор має знати про актуальну флористику. Розвину надивленість та наближу до проєктів мрії",
        theme: ""
    },
];

export default euroSpeakerData;