export const priceData = [
    // {
    //     name: "Базовий",
    //     addText: "",
    //     price: 650,
    //     prevPrice: 0,
    //     payLink: "https://secure.wayforpay.com/button/b5d4814aa01b7",
    //     avlServices: [
    //         "Доступ до всіх навчальних матеріалів",
    //         "Повна документація декоратора",
    //         "Спільний чат для спілкування",
    //         "Участь у зустрічах в форматі “питання-відповідь”",
    //         "Диплом",
    //     ],
    //     notAvlServices: [
    //         "Домашні завдання з перевіркою особисто від Марти та Аліси",
    //         "Індивідуальна консультація (1 год) ",
    //         "Додаткові поглиблені зуми від запрошених експертів",
    //         "Вечеря з командою Mopis (26 квітня Львів)",
    //         "Практика на проєкті",
    //     ]
    // },

    // {
    //     name: "Розширений",
    //     addText: "",
    //     price: 950,
    //     prevPrice: 0,
    //     payLink: "https://secure.wayforpay.com/button/bc380d7c09874",
    //     avlServices: [
    //         "Доступ до всіх навчальних матеріалів",
    //         "Повна документація декоратора",
    //         "Спільний чат для спілкування",
    //         "Участь у зустрічах в форматі “питання-відповідь”",
    //         "Диплом",
    //         "Домашні завдання з перевіркою особисто від Марти та Аліси",
    //         "Індивідуальна консультація (1 год) ",
    //         "Додаткові поглиблені зуми від запрошених експертів",
    //     ],
    //     notAvlServices: [
    //         "Вечеря з командою Mopis (26 квітня Львів)",
    //         "Практика на проєкті",
    //     ]
    // },

    // {
    //     name: "VIP",
    //     addText: "",
    //     price: 1200,
    //     prevPrice: 0,
    //     payLink: " https://secure.wayforpay.com/button/b25f06163890e",
    //     avlServices: [
    //         "Доступ до всіх навчальних матеріалів",
    //         "Повна документація декоратора",
    //         "Спільний чат для спілкування",
    //         "Участь у зустрічах в форматі “питання-відповідь”",
    //         "Диплом",
    //         "Домашні завдання з перевіркою особисто від Марти та Аліси",
    //         "Індивідуальна консультація (1 год) ",
    //         "Додаткові поглиблені зуми від запрошених експертів",
    //         "Вечеря з командою Mopis (26 квітня Львів)",
    //         "Практика на проєкті",
    //     ],
    //     notAvlServices: []
    // },

    {
        name: "Full",
        addText: "",
        price: 950,
        prevPrice: 0,
        payLink: "https://secure.wayforpay.com/button/bad2448706318",
        avlServices: [
            "Доступ до всіх навчальних матеріалів",
            "Повна документація декоратора",
            "Додаткові поглиблені відео від запрошених експертів",
            "Індивідуальна консультація (1 год)",
            "Диплом",
            
        ],
        notAvlServices: []
    },
];

export const mentoringData = [
    `все що у тарифі “VIP”`,
    "перевірка домашніх завдань та зворотній звʼязок від Аліси",
    "маркетинговий план та повна стратегія для інстаграм від Аліси",
    "6 місяців супроводу по завершенню (1 зустріч в місяць + спілкування в межах поточних завдань та проєктів)"
]