import './PublicOffer.scss';

function PublicOffer () {
    return (
        <section className='public-offer'>
            <div className='public-offer-container'>
                <iframe 
                    src='https://docs.google.com/document/d/e/2PACX-1vTHlc0ZVL-Fjh_SGucKiTyPsQ6PXfwRniWqPun7yQlJXHLfELG1WlNpmBwQYp4y_3ikFHHEB1ZdmcgS/pub?embedded=true'
                    title='Публічна оферта'
                />
            </div>
        </section>
    )
};

export default PublicOffer;