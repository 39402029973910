const descriptionData = [
    {
        mainText: "Новачків",
        description: "які мріють освоїти професію декоратора"
    },

    {
        mainText: "З досвідом",
        description: "Практикуючих декораторів  які прагнуть систематизувати роботу та вийти на новий рівень. Створювати проєкти нового масштабу та заробляти більше."
    },
];

export default descriptionData;