import DOC from '../../images/doc-icon.svg';
import PDF from '../../images/pdf-icon.svg';
import MP4 from '../../images/mp4-icon.svg';
import XLSX from '../../images/xlsx-icon.svg';
import JPG from '../../images/jpg-icon.svg';

// This Icon Is In Design, But Never Used
// import PPTX from '../../images/pptx-icon.svg';


const programData = [

    // BLOCK #1

    {
        name: "Вступний",
        description: "Огляд скриньки декоратора Mopis",
        steps: [
            "Welcome-zoom знайомство",
            "Історія Mopis Decor. Шлях, кейси та приклади",
            "Хто такий декоратор. Обов’язки та посадова інструкція",
            "Шлях декоратора. Можливості та перспективи",
            "5 помилок декоратора-початківця та декоратора з досвідом",
            "Hard skills: кольори, матеріали, інструмент",
            "Тренди 2024-2025 р.",
            "Inspiration-zoom: не бійся, а твори!"
        ]
    },

    // BLOCK #2

    {
        name: "Документація декоратора",
        description: "Розбір 5 презентацій декору Mopis в різних стилістиках",
        steps: [
            "Презентація декору: структура, шаблон та приклади",
            "Кошторис декору для клієнта: варіанти формування, розбір де декоратор втрачає гроші",
            "Договір на послуги декору (українська та англійська) ",
            "Файл “Розрахунок флористики” + гайдлайн для використання",
            "Таблиця “Попередня собівартість проєкту” + гайдлайн до використання",
            "Чек-лист підготовки проєкту",
            "Правила роботи на майданчику",
            "Документація монтажу: шаблони та приклади",
            "Шаблон закриття проєкту та розрахунок рентабельності",
            "Документація агенції: рух коштів та P&L"
        ]
    }, 

    // BLOCK #3

    {
        name: "Підготовка до проєкту",
        description: "Контакт-лист підрядників",
        steps: [
            "Комунікація з нареченою: перші кроки після підписання договору",
            "Технічний ефір: від ідеї до готового виробу",
            "Т3. Оренда декору",
            "Планування логістики ",
            "Організація процесу підготовки ",
            "Розширений урок по флористиці ",
            "Комунікація організатор & декоратор",
            "Фіналізація проєкту. Планування монтажу"
        ]
    }, 

    // BLOCK #4

    {
        name: "Реалізація проєкту",
        description: "Урок про світло",
        steps: [
            "Монтаж: розподіл зон відповідальності та дедлайнів",
            "Чому деякі декоратори затримують здачу майданчику і як цього уникати",
            "Форс-мажори на майданчику. Приклади та план вирішення",
            "Комунікація під час монтажу. Правила та табу Mopis",
            "Очікування-реальність. Чому часом реалізація не відповідає ескізам",
            "КОНТЕНТ та висвітлення проєкту",
            "Демонтаж та закриття проєкту",
            "ZOOM зустріч для фіналізації роботи декоратора та розбору питань",
        ]
    }, 

    // BLOCK #5

    {
        name: "Маркетинг та продажі",
        description: "Індивідуальний розбір інстаграм сторінки особисто від Аліси",
        steps: [
            "Позиціонування декоратора: як знайти свою нішу та отримувати проєкти мрії",
            "Портфоліо декоратора",
            "Маркетингові інструменти які працюють",
            "Як отримати перші замовлення, якщо ти починаєш з нуля",
            "Інстаграм декоратора: актуальні інструменти",
            "Як отримати співпраці з організаторами",
            "Ціна на послуги та як продавати дорого. Чому ми не робимо знижки.",
            "Перша зустріч з клієнтом. Як закрити проєкт та отримати завдаток",
        ]
    }, 

    // BLOCK #6

    {
        name: "Завершальний",
        description: "Розіграш практики на весіллі в Європі",
        steps: [
            "Inspiration гайд: де черпати натхнення",
            "Розбір проектів Mopis: Едем, Венеція, Женева",
            "Декор за 30 000 євро: аналіз та приклади",
            "GPT: як варто та не варто використовувати в роботі",
            "Фінальний зум: питання-відповіді",
            "Чек-лист дій по завершенні курсу"
        ]
    }, 
];

export default programData;