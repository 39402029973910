import './ProjectItem.scss';
import ReactPlayer from 'react-player';
import { useRef , useLayoutEffect , useState } from 'react';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import CrossIcon from '../../../images/close-menu-icon.svg';

const ProjectItem = (props) => {
    const isMobile = useMediaQuery('(max-width: 700px)');

    const [isFullScreen, setFullScreen] = useState(false);
    // const [isVideoOpen, setVideoOpen] = useState(false);

    const videoRef = useRef(null);
    const videoContainerRef = useRef(null);
    const videoContainerInnerRef = useRef(null);

    const header = document.getElementById('header');

    const enterFullScreen = () => {
        const elem = videoRef.current;

        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            /* Safari */
            elem.webkitRequestFullscreen();
            
        } else if (elem.webkitEnterFullscreen) {
            /* Safari */
            elem.webkitEnterFullscreen();
        } else if (elem.msRequestFullscreen) {
            /* IE11 */
            elem.msRequestFullscreen();
            
        } else if (elem.mozRequestFullScreen) {
            /* Mozila */
            elem.mozRequestFullScreen();
        };
    };

    function exitFullscreen() {
        const elem = document;

        if (elem.exitFullscreen) {
            elem.exitFullscreen();

        } else if (elem.mozCancelFullScreen) {
            elem.mozCancelFullScreen();

        } else if (elem.webkitExitFullscreen) {
            elem.webkitExitFullscreen(); 
        };
    };


    const handleFullScreen = () => {
        if (isMobile) {   
            videoRef.current.style.width = '100%';
            videoRef.current.style.height = '100%';
            videoRef.current.style.zIndex = '1';

            document.body.style.overflow = 'hidden';

            header.style.display = 'none';

            videoContainerRef.current.style.width = '100%';
            videoContainerRef.current.style.height = '100vh';
            videoContainerRef.current.style.padding = '0';
            videoContainerRef.current.style.zIndex = '3000';
            videoContainerRef.current.style.position = 'fixed';
            videoContainerRef.current.style.backgroundColor = 'grey';
            videoContainerRef.current.style.top = '0';
            videoContainerRef.current.style.left = '0';

            videoContainerInnerRef.current.style.display = 'none';

        } else {
            enterFullScreen();
        };
        
        setFullScreen(true);
    }

    const handleFullScreenClose = () => {
        if (window.innerWidth < 700 && window.innerWidth > 500) { 
            videoRef.current.style.width = '100%!important';
            videoRef.current.style.height = '100%!important';
            videoRef.current.style.zIndex = '-1';
            
            document.body.style.overflow = 'unset';

            header.style.display = 'flex';

            videoContainerRef.current.style.width = '285px';
            videoContainerRef.current.style.height = '439px';
            videoContainerRef.current.style.position = 'relative';
            videoContainerRef.current.style.zIndex = '1';
            
            videoContainerInnerRef.current.style.display = 'flex';

        } else if (window.innerWidth < 500) {
            videoRef.current.style.width = '100%!important';
            videoRef.current.style.height = '100%!important';
            videoRef.current.style.zIndex = '-1';
            
            document.body.style.overflow = 'unset';

            header.style.display = 'flex';

            videoContainerRef.current.style.backgroundColor = 'unset';
            videoContainerRef.current.style.width = 'calc(100% - 32px)';
            videoContainerRef.current.style.height = '528px';
            videoContainerRef.current.style.position = 'relative';
            videoContainerRef.current.style.zIndex = '1';
            videoContainerRef.current.style.padding = '0 16px';
            
            videoContainerInnerRef.current.style.display = 'flex';
        } 
        // else if (window.innerWidth < 450) {
        //     videoRef.current.style.width = '100px';
        //     videoRef.current.style.height = '50px';
        //     videoRef.current.style.zIndex = '-1';
        //     videoRef.current.style.left = '10px';
        //     document.body.style.overflow = 'unset';
        //     videoContainerRef.current.style.width = '100%';
        //     videoContainerRef.current.style.height = '380px';
        //     // ref.current!.style.width = '100%';
        //     // ref.current!.style.height = '380px';
        //     // ref.current!.style.zIndex = 'unset';
        //     // ref.current!.style.position = 'relative';
        //     // ref.current!.style.backgroundColor = 'none';
        //     // ref.current!.style.top = 'unset';
        //     // ref.current!.style.left = 'unset';
        //     videoContainerInnerRef.current.style.display = 'flex';
        // };

        setFullScreen(false);

        if(window.innerWidth > 850) {
            exitFullscreen();
        };
    }

    const handleFullScreenChange = () => {
        if (window.innerHeight !== videoRef.current.offsetHeight) {
            setFullScreen(false);
        }
    }

    useLayoutEffect(() => {
        const currentVideoRef = videoRef.current;

        currentVideoRef.addEventListener('fullscreenchange', handleFullScreenChange);
        currentVideoRef.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    
        return () => {
            currentVideoRef.removeEventListener('fullscreenchange', handleFullScreenChange);
            currentVideoRef.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
        };
    }, []);

    return (
        <div className='project-item'>
            <div className='project-item-title'>
                <h4>{props.title}</h4>
                <div></div>
            </div>

            <div className='project-item-info-box'>
                <div className='project-slider'>
                    <div className='project-slider-box'>
                        <div className='project-image' >
                            <img src={props.image1} alt={'event'}/>
                        </div>

                        <div className='project-image' >
                            <img src={props.image2} alt={'event'}/>
                        </div>

                        <div className='project-image'>
                            <img src={props.image3} alt={'event'}/>
                        </div>
                    </div>

                    <div className='project-slider-box'>
                        <div className='project-image' >
                            <img src={props.image1} alt={'event'}/>
                        </div>

                        <div className='project-image' >
                            <img src={props.image2} alt={'event'}/>
                        </div>

                        <div className='project-image'>
                            <img src={props.image3} alt={'event'}/>
                        </div>
                    </div>

                    <div className='project-slider-box'>
                        <div className='project-image' >
                            <img src={props.image1} alt={'event'}/>
                        </div>

                        <div className='project-image' >
                            <img src={props.image2} alt={'event'}/>
                        </div>

                        <div className='project-image'>
                            <img src={props.image3} alt={'event'}/>
                        </div>
                    </div>
                </div>

                <div className='project-video' ref={videoContainerRef}>

                    <div ref={videoRef}>
                        <img src={CrossIcon} alt={'Close Video Button'} className='cross-icon' onClick={handleFullScreenClose}/>

                        <ReactPlayer
                            loading="lazy"
                            className='project-video-player'
                            url={props.url}
                            controls={true}
                            loop={false}
                            playing={isFullScreen ? true : false}
                            playsinline={true}
                            volume={0.5}
                            muted={isFullScreen ? true : true}
                            config={{ vimeo: { playerOptions: { transcript: false, pip: false}}}}
                            onEnded={() => {
                                if(isFullScreen) {
                                    handleFullScreenClose();
                                }
                            }}
                        />
                    </div>

                    <div className='project-video-container' ref={videoContainerInnerRef} onClick={handleFullScreen}>
                        <div className='project-video-button'>Дивитись <br/> Showreel</div>

                        <img src={props.preloader} alt={'preloader'} className='project-video-preloader'/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectItem
